import type { GlobalPageBannerType } from '~/types';

export const BannerState = () => useState<boolean>('keyGlobalBannerShow', () => true);
export const BannerType = () => useState<GlobalPageBannerType>('keyGlobalBannerType', () => 'shipping-info');

export function useGlobalPageBanner() {
    const bannerState = BannerState();
    const bannerType = BannerType();

    function toggleBanner(show: boolean) {
        bannerState.value = show;
    }

    return {
        bannerState,
        bannerType,
        toggleBanner
    };
}
